import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <p>Created by AJ Sevillano - devchallenges.io</p>
    </div>
  );
};

export default Footer;
